<template>
  <div id="submissionTileView">

    <div class="image-wrapper"> 
        <img class="image" :src="imagePath"> 
    </div>

    <div class="info">
        <div class="info-title">{{title}}</div>
        <div class="info-date">Submitted on {{date}}</div>
    </div>
   
    <img class="new-entry" v-if="newEntry" :src="require('@/assets/title-your-entry.png')">
  </div>
</template>

<script>
export default {
  name: "submissionTileView.vue",
  props: {
    title: String,
    artist: String,
    date: String,
    imagePath: String,
    newEntry: Boolean
  }
}
</script>

<style scoped>
#submissionTileView {
  width: auto;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.7);
  background-color: #1C2233;
  position: relative;
}

.image-wrapper {
  
}

.image {
  display:block;
  max-width:100%;
}

.new-entry {
  position:absolute;
  width:142px;
  top:0;
  right:0;
}

.info{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin: 20px 10px 0 10px;
  line-height: 16px;
}

.info .info-title{
  
  font-weight: 500;
  color: #fff;
  text-align: left;
}

.info .info-date{
  
  font-weight: normal;
  color: #565F79;
  text-align: right;
}

/* REPONSIVE SMALL DESKTOP--------------------- */
@media (max-width: 1200px) {
  #submissionTileView {
    margin-left:0;
    margin-right:0;
  }
}

/* REPONSIVE PHONE--------------------- */
@media (max-width: 768px) {
  #submissionTileView {
    padding: 0px;
  } 

  .image {
    border-radius: 10px 10px 0 0;
  }

  .info{
    margin: 0 20px;
    padding: 20px 0;
  }
}

</style>
