<template>
  <div id="app">

    <div class="flag">
      <video autoplay muted loop playsinline>
        <source :src="require('@/assets/img-sabotage-flag.mp4')" type="video/quicktime">
        <source :src="require('@/assets/img-sabotage-flag.webm')" type="video/webm">
      </video>
    </div>

    <div class="mobile-header">
      <img :src="require('@/assets/title-fan-art-contest.svg')">
    </div>
    <prizeAndRulesView/>
    <a id="steps" name="steps" />
    <div id="global-wrapper">
        <closedFormView/>
     </div>
  </div>

</template>

<script>
import closedFormView from './components/closedFormView.vue'
import prizeAndRulesView from "@/components/prizeAndRulesView.vue";


export default {
  name: 'App',
  components: {
    closedFormView,
    prizeAndRulesView,
  },
  created () {
    document.title = "Sea of Stars | Fan Art Contest";
  }

}
</script>

<style>
#app {
  display:block;
  color:#FFF1CD;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width:100%;
  min-height:100%;
  margin:auto;
  position:relative;
  box-sizing: border-box;
  overflow-x: hidden;
}

#global-wrapper {
  display:block;
  height:auto;
  position:relative;
  margin: auto;
  padding-left: 50%;
  padding-right: 40px;
  padding-top: 56px;
}

.flag{
  position: fixed;
  top: 0;
  right: 24px;
  z-index: 999;
}

.flag video{
  width: 120px;
  height: 127px;
}

.mobile-header{
  display: none;
}

/* REPONSIVE SMALL DESKTOP ---------------------- */
@media (max-width: 1200px) {
  #global-wrapper {
    padding-right: 0;
  }

  .flag{
    right: 10px;
  }

  .flag video{
    width: 100px;
    height: 106px;
  }
}

/* REPONSIVE PHONE ---------------------- */
@media (max-width: 768px) {

  #global-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }

  .flag{
    display: block;
    position: relative;
    right: 0;
  }

  .flag video{
    width: 120px;
    height: 127px;
    display: block;
    margin: auto;
  }

  .mobile-header{
    display: block;
    margin-top:-12px ;
    z-index: 10;
    position: relative;
  }

  .mobile-header img{
    max-width: 100%;
  }


}


</style>
