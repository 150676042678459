<template>
  <div id="footerTileView">
    
    <div id="btnList">
      <a href="https://seaofstarsgame.co/fan-art/" target="_blank">VIEW MORE FAN ART</a>
      <a href="https://seaofstarsgame.co/" target="_blank">SEA OF STARS WEBSITE</a>
      <a href="https://sabotagestudio.com/" target="_blank">SABOTAGE WEBSITE</a>
    </div>

    <div id="socialList">
      <a href="https://twitter.com/seaofstarsgame" target="_blank">
        <img :src="require('@/assets/icn-twitter.svg')">
      </a>
      <a href="https://www.facebook.com/SabotageQC" target="_blank">
        <img :src="require('@/assets/icn-facebook.svg')">
      </a>
      <a href="https://www.instagram.com/sabotage.studio/" target="_blank">
        <img :src="require('@/assets/icn-instagram.svg')">
      </a>
      <a href="https://discord.gg/sabotagestudio" target="_blank">
        <img :src="require('@/assets/icn-discord.svg')">
      </a>
    </div>

  </div>
</template>

<script>
export default {
  name: "footerTileView.vue",
  props: {
    
  }
}
</script>

<style scoped>
#footerTileView {
  width: auto;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.7);
  background-color: #1C2233;
  position: relative;
}

#btnList a{
  display: block;
  width: auto;
  height: 50px;
  border-bottom: 1px solid #333B53;
  border-radius: 0px;

  font-size: 14px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 1px;
  line-height: 50px;
  text-decoration: none;
}

#btnList a:hover{
  border-radius: 4px;
  background: linear-gradient(252deg, #0077e1, #3fd9be);
}

#socialList{
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 24px;
}

#socialList a{
  opacity: 0.6;
  transition: opacity 0.2s;
}

#socialList a:hover{
  opacity: 1.0;
}


#socialList a img{
  height: 30px;
}

/* REPONSIVE SMALL DESKTOP--------------------- */
@media (max-width: 1200px) {
  #footerTileView {
    margin-left:0;
    margin-right:0;
  }
}

/* REPONSIVE PHONE--------------------- */
@media (max-width: 768px) {
  #footerTileView {
    
  } 

  #btnList a{
    height: 60px;
    font-size: 18px;
    line-height: 60px;
  }

  #socialList{
    gap: 30px;
  }

  #socialList a img{
    height: 40px;
  }

}
</style>
