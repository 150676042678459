<template>
  <div class="form">
    <div id="header" class="mobile-hidden">
      <img :src="require('@/assets/title-fan-art-contest.svg')">
    </div>

    <div class="fold">
      <img :src="require('@/assets/title-submissions-closed.svg')" class="submissions-closed">
      <p>Thank you to all who participated in the Sea of Stars fan art contest! The sheer quantity and exceptional quality of submissions surpassed our expectations. Stay tuned for updates on <a href="https://x.com/seaofstarsgame" target="_blank">Twitter</a> or <a href="https://discord.gg/sabotagestudio" target="_blank">Discord</a> for the next steps. Winners will also be notified via email. Cheers &#x1F9E1;</p>
      <li class="submissions-list" v-for="(submission) in submissions" v-bind:key="submission.id" ref="submission">
        <submissionTileView :title=submission.pieceName :artist=submission.artistName :date=submission.date :imagePath=submission.imagePath :newEntry=false />
      </li>
      <footerTileView />

    </div>

  </div>
</template>

<script>

import submissionTileView from "@/components/submissionTileView.vue"
import footerTileView from "@/components/footerTileView.vue"
import axios from "axios"
import eventBus from '../event-bus.js'
import * as formService from '../services/formService'

export default {
  name: 'closedFormView',
  components: {
    submissionTileView,
    footerTileView
  },
  props: {
  },
  data () {
    return {
      submissions:[]
    }
  },
  mounted:function() {
    this.getData();
  },
  methods: {
    getData: async function () {
      const { data } = await axios.get("https://fanart-contest.nyc3.cdn.digitaloceanspaces.com/data/submissions.json");
      this.submissions = data.submissions;
    },
    getDate:function(){
      var d = new Date();
      var day = d.getDate();
      var month = d.getMonth() + 1;
      var year = d.getFullYear();

      if(day < 10) day = "0" + day;
      if(month < 10) month = "0" + month;

      return  day + "/" + month + "/" + year;
    }

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0;
}
a {
  color: #FFF1CD;
}

p{
  margin: 0 -30px 20px -30px;
  line-height: 25px;
}

.form {
  width:500px;
  margin: auto;
}

.form.loading{
  pointer-events: none;
}

#header img{
  display: block;
  max-width: 100%;
  margin: auto;
}

.submissions-closed{
  margin: 20px auto 12px auto;
  box-shadow: 0 25px 15px -10px rgba(0, 0, 0, 0.3)
}

a#steps{
  display: none;
}
/* SMALL DESKTOP SLIM FORM ---------------------- */
@media (max-width: 1440px) {

  .form {
    width:400px;
  }
}

/* REPONSIVE PHONE ---------------------- */
@media (max-width: 768px) {
  a#steps{
    display: block;
  }

  .form {
    width:100%;
    margin: auto;
  }

  p{
    font-size: 18px;
    line-height: 1.25;
    margin: 0 auto 20px auto;
  }

}


</style>
