<template>
  <div id="prizeAndRules" >
    
  <div id="scrollContainer" ref="lane">
    <div id="prizeContainer" v-bind:class="{ selected: currentView=='prize'}">
      <div id="cover">
        <img id="badge" :src="require('@/assets/title-winning-badge.svg')" >
      </div>
    </div>

      <div id="rulesContainer" v-bind:class="{ selected: currentView=='rules'}">
        <div id="cover">
          <img id="goodLuck" :src="require('@/assets/title-good-luck.png')" >
        </div>
        <div id="rulesList">
          
          <div class="ruleTitle">
            CONTEST DETAILS
          </div>

          <div class="rulesLeft">
              <div class="rule">
                <span>Deadline</span>
                <p>You have until June 7th to submit your fan art!</p>
              </div>
              <div class="rule">
                <span>Submitting Multiple Artworks</span>
                <p>There are no rules against submitting multiple pieces of fan art, but you’ll have to submit a unique form for each of them.</p>
              </div>

              <div class="rule">
                <span>Winners Selection & Prizes</span>
                  <ul class="threeRounds">
                    <li><strong>Grand Prize (picked by Bryce & Thierry):</strong> 1 signed art book, 1 signed gold foil print & 3 large prints</li>
                    <li><strong>Saboteam Award:</strong> 1 signed art book & 3 large prints</li>
                    <li><strong>Community Award:</strong> 1 signed art book & 3 large prints</li>
                    <li><strong>Participation Prizes:</strong> 7 large prints randomly awarded to participants</li>
                  </ul>
                
              </div>
          </div>

          <div class="rulesRight">
              <div class="rule">
                <span>Fan Art Created Prior to this Contest</span>
                <p>Your piece doesn’t have to be created specifically for this contest. Fans and artists have been drawing magnificent artwork for Sea of Stars for many years now and all pieces are welcome.</p>
              </div>
              <div class="rule">
                <span>Winners Announcement</span>
                <p>The winners will be announced during a livestream in June (specific details will come later). They will also get contacted via email at the address submitted in this form.</p>
              </div>
              <div class="rule">
                <span>Prize Shipping Cost</span>
                <p>Don’t worry about it, international shipping is on us!</p>
              </div>
          </div>

        </div>
      </div>
    </div>

     <div id="segmented">
      <div class="btnSegmented" @click="showPrize()" v-bind:class="{ selected: currentView=='prize'}">
         <svg height="38" viewBox="0 0 198 38" width="198" xmlns="http://www.w3.org/2000/svg"><path d="m106 0 4 8h88l-4.789809 30h-193.210191l4.78980892-30h93.21019108z" fill-rule="evenodd"/></svg>
          <span>Winning Prize</span>
      </div>
      <div class="btnSegmented" @click="showRules()" v-bind:class="{ selected: currentView=='rules'}">
          <svg height="38" viewBox="0 0 198 38" width="198" xmlns="http://www.w3.org/2000/svg"><path d="m106 0 4 8h88l-4.789809 30h-193.210191l4.78980892-30h93.21019108z" fill-rule="evenodd"/></svg>
          <span>Contest Details</span>
      </div>
        
        <!--
        <LottieAnimation 
          :animation-data="ButtonWinningPrize"
          :auto-play="true"
          :loop="true"
          :speed="1"
          ref="anim" />
        -->
        
    </div>
  </div>
    
</template>

<script>
import { gsap } from 'gsap';
/*
import { LottieAnimation } from 'lottie-web-vue';
import ButtonWinningPrize from "./../assets/lottie/btn-winning-prize.json"
*/

export default {
  name: "prizeAndRulesView.vue",
  props: {
   
  },
  data (){
      return{
        currentView:"prize"
      }
    },
    mounted:function() {
     window.addEventListener("resize", this.snapScroll);
    },
    updated:function() {
      
    },
    methods:{
      showPrize:function(){
        if(!this.$refs.lane) return;

        var newPosX = 0;
        gsap.to(this.$refs.lane, {duration: 0.75, scrollLeft: newPosX, ease:"power2.inOut"});
        this.currentView="prize";
      },
      showRules:function(){
        if(!this.$refs.lane) return;

        var newPosX = this.$refs.lane.offsetWidth;
        gsap.to(this.$refs.lane, {duration: 0.75, scrollLeft: newPosX, ease:"power2.inOut"});
        this.currentView="rules";
      },
      snapScroll:function(){
        if(this.currentView == "prize"){
          this.showPrize();
        }else{
          this.showRules();
        }
      }
    }
  }
</script>

<style scoped>
  #prizeAndRules {
    position: fixed;
    top: 40px;
    left: 40px;
    bottom: 40px;
    right: 50vw;
   
    z-index:5;
    border-radius: 30px;

    background-color: black;
    background: url('../assets/bg-hero-3.webp') center center no-repeat;
    background-size: cover;
    box-shadow: 0 40px 20px -20px rgba(6, 27, 45, 0.42);
  }

  #scrollContainer{
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  /* PRIZE ---------------------------------- */
  #prizeContainer{
    flex-basis: 100%;
    flex-shrink: 0;
    width: 100%;

    display: flex;
    flex-direction: column;
    margin: 12vh auto 12vh auto;
  }

  #prizeContainer #cover{
    width: 100%;
    background: url('../assets/img-artbook-standing-cover.webp') center center no-repeat;
    background-size: cover;
    flex-basis: auto;
    flex-grow: 3;
  }

  #prizeContainer #cover #badge{
    margin-top: -50px;
    /*margin-right: -420px;*/
    margin-right: -50vh;
  }

  

 /* RULES ---------------------------------- */
  #rulesContainer{
    flex-basis: 100%;
    flex-shrink: 0;
    width: 100%;
    max-height: 100%;
    color: #fff7e2;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 50px auto 86px auto;
  }

  #rulesContainer #cover{
    flex-grow: 1;
    background-color: blue;
    background: url('../assets/img-artbook-flat-angle.webp') center center no-repeat;
    background-size: contain;
    position: relative;
  }

  #rulesContainer #cover #goodLuck{
    width: 187px;
    position: absolute;
    bottom: -30px;
    right: 30px;
  }


  #rulesContainer #rulesList{
    margin-left: 66px;
    margin-right: 66px;
    display: flex;
    flex-wrap:wrap;
    gap: 18px;
    text-align: left;
  }

  #rulesContainer #rulesList .ruleTitle{
    font-family: Barlow;
    font-size: 26px;
    font-weight: 900;
    font-style: italic;
    flex-basis: 100%;
    text-align: left;
    line-height: 1;
  }

  #rulesContainer #rulesList .rulesLeft,
  #rulesContainer #rulesList .rulesRight
  {
    flex-basis: calc(50% - 9px);
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  #rulesContainer #rulesList .rule {
    font-size: 16px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.15;
  }

  #rulesContainer #rulesList .rule span {
    font-weight: bold;
  }
  #rulesContainer #rulesList .rule p {
    margin: 0;
  }

  ul.threeRounds{
    padding: 6px 0 0 16px;
    margin: 0;
  }
  ul.threeRounds li{
    list-style: inherit !important;
    list-style: square !important; 
    list-style-type: square !important;
    margin: 0;
    padding-left: 6px;
    font-size: 15px;
  }


  /* SEGMENTED ---------------------------------- */
  #segmented{
    position: absolute;
    width: 100%;
    margin: auto;
    bottom: 28px;

    display: flex;
    justify-content: center;
  }

  .btnSegmented{
    width: 198px;
    height: 38px;
    cursor: pointer;
  }

  .btnSegmented svg{
    fill: #60978C;
    fill-opacity: 0.7;
    transition: all 0.3s;
  }

  .btnSegmented.selected svg{
    fill: #131B22;
    fill-opacity: 1;
  }

  .btnSegmented:hover svg{
    fill-opacity: 1;
  }

  .btnSegmented span{
    display: block;
    margin-top: -30px;

    font-family: "Barlow", sans-serif;
    font-weight: 900;
    font-style: italic;
    font-size: 16px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    color: #FFF7E2;
    transition: color 0.3s;
  }

  .btnSegmented.selected span{
    color: #FFCE85;
  }

/* REPONSIVE SMALL DESKTOP ---------------------- */
@media (max-width: 1200px) {
  #prizeAndRules {
    top: 0;
    left: 0;
    bottom: 0;
    right: 50vw;
    border-radius: 0;
    box-shadow: none;
  }

    #prizeContainer{
      margin: 120px auto 130px auto;
    }

    #prizeContainer #cover #badge{
      margin-top: -30px;
      margin-right: -30vh;
    }

  #rulesContainer #rulesList{
    margin-left: 46px;
    margin-right: 46px;
  }
}

/* REPONSIVE PHONE ---------------------- */
@media (max-width: 768px) {
  #prizeAndRules {
    position: inherit;
    border-radius: 15px;
    margin: 15px 15px 0 15px;
  }

  #scrollContainer{
    height: auto;
  }

  #prizeContainer{
    margin: 82px auto 38px auto;
    display: none;
  }

  #prizeContainer.selected{
    display: block;
  }

  #prizeContainer #cover{
    height: 375px;
    flex-grow: 0;
    position: relative;
  }

  #prizeContainer #cover #badge{
    position: absolute;
    margin: auto;
    top: -24px;
    right: -20px;
    transform: scale(0.8);
  }

 #rulesContainer{
    margin: 92px auto 45px auto;
    justify-content: flex-start;
    display: none;
  }

  #rulesContainer.selected{
    display: block;
  }

 #rulesContainer #rulesList{
    margin-left: 30px;
    margin-right: 30px;
  }

  #rulesContainer #cover{
    height: 280px;
    display: none;
  }

  #rulesContainer #cover #goodLuck{
    position: relative;
    display: none;
    margin: auto;
    bottom: auto;
    right: auto;
  }

  #rulesContainer #rulesList .rulesLeft,
  #rulesContainer #rulesList .rulesRight
  {
    flex-basis: 100%;
  }

  #rulesContainer #rulesList .rule{
    font-size: 20px;
  }

  ul.threeRounds li{
    font-size: 20px;
  }


  /* REPONSIVE SEGMENTED ---------------- */
  #segmented{
    top:0;
    bottom: auto;
    
  }

  .btnSegmented{
    width: 50%;
    height: 62px;
    background: url('../assets/bg-btn-segmented-mobile.png') repeat-x;
    background-size: cover;
    box-shadow: inset 5px -5px 9px 0 rgba(0, 0, 0, 0.2);
  }

  .btnSegmented:first-child{
     border-radius: 14px 0 0 0;
  }

  .btnSegmented:nth-child(2){
     border-radius: 0 14px 0 0;
  }

  .btnSegmented.selected{
    background-image: none;
    box-shadow: none;
  }

  .btnSegmented svg{
    display: none;
  }

  .btnSegmented span{
    margin-top: 0;
    line-height: 62px;
    letter-spacing: 1px;
    color: #FFF7E2;
  }

  .btnSegmented.selected span{
    color: #043445;
  }

  
}

</style>
